import React, { useEffect, useState } from 'react';
import { 
    Text, 
    View, 
    StyleSheet,
    TouchableOpacity, 
} from 'react-native';
import { movementsStyles } from './movements.styles';
import HeaderPageTitle from '../../common/HeaderPageTitle';
import { generalStyles } from '../../styles/general.styles';
import { getMovementsQuery } from '../../services/axios';
import Loading from '../../common/Loading';
import { withRouter } from 'react-router-dom';
import { notify } from '../../utils/notify';
import { styles } from '../../styles/modalMovements.styles';
import isMobile from '../../utils/dimensions';
import { format } from 'date-fns';
import askIA from '../../services/ia-assistant';
import { Card } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import ModalBtn from '../../components/modalBtn/modalBtn';

const Movements = ({ history }) => {

  const clientInfo = JSON.parse(localStorage.getItem('informacionCliente'))
  const [data, setData] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentMessagesPjud, setCurrentMessagesPjud] = useState(null)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false);
  const [modalAbstract, setModalAbstract] = useState(null)


  if(localStorage.getItem('token')==null){
    localStorage.removeItem('token')
    history.push('/iniciar-sesion')
    return
  }

  useEffect(()=>{ 
    if(windowHeight>500){
     setWindowHeight(500)
    }else{
      setWindowHeight(windowHeight-100)
    }

    if(windowWidth>800){
      setWindowWidth("800px")
    }else{
      setWindowWidth("90%")
    }
    
  },[])

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')  // Enero es 0, así que sumamos 1
    const year = date.getFullYear()
  
    return `${day}-${month}-${year}`
  }

  const TableRow = ({ data, header = false }) => (
    <View style={styles.row}>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cell,
          styles.cellFechaCreacion,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {header ? data.fechaActualizacion : formatDate(data.fechaActualizacion)}
      </Text>
      {/*<Text
        style={[
          styles.cell,
          styles.cellFechaCreacion,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.fechaTermino}
      </Text>
      <Text
        style={[
          styles.cell,
          styles.cellEstado,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.estado}
      </Text>}*/}
      
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cell,
          styles.cellMensaje,
          header && styles.header, // Esto debería estar al final
        ]}
      >
        {data.mensaje}
      </Text>
    </View>
  )
  const TableRowPjud = ({ data, header = false }) => (
    <View style={styles.row}>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellFechaCreacion,
          header && styles.headerPjud,
        ]}
      >
        {header ? data.fecha_doc : formatDate(data.fecha_doc)}
      </Text>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellEstado,
          header && styles.headerPjud,
        ]}
      >
        {data.tramite}
      </Text>
      <Text
        style={[
          {marginBottom: '10px'},
          styles.cellPjud,
          styles.cellMensaje,
          header && styles.headerPjud,
        ]}
      >
        {data.desc_tramite}
      </Text>
      {header ? (
        <Text
          style={[
            {marginBottom: '10px'},
            styles.cellPjud,
            styles.cellMensaje,
            styles.headerPjud,
          ]}
        >
          {data.url_doc}
        </Text>
      ) : (
        <a 
          href={data.url_doc} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={styles.link}
        >
          Descargar Documento
        </a>
      )}
    </View>
  )

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await getMovementsQuery(
        '/Client/InformacionCausas',
        localStorage.getItem('token')
      )
      setLoading(false)
      setData(response.data)
    } catch (error) {
      setLoading(false)
      if(error.response.status==401){
        localStorage.removeItem('token')
        history.push('/iniciar-sesion')
      }else{
        const err = error.response
        notify('error', err)
      }
    }
  }

  const buildCaseAbstract = async(...args) => {
    setModalAbstract(null);
    try {
      const iaResponse = await askIA(...args);

      if(iaResponse.ok){
        setModalAbstract(iaResponse.response);
      } else {
        setModalAbstract('No hemos podido obtener un resúmen de tu caso. Por favor consulta el historial de tu caso que se encuentra más abajo.');
      }
    } catch {
      setModalAbstract('No hemos podido obtener un resúmen de tu caso. Por favor consulta el historial de tu caso que se encuentra más abajo.');

    }
  }

  const movementLookUp = (movement) => {

      if(movement.activo){
        const { 
            tribunal, 
            rol, 
            titulo, 
            tablaMovimientos,
            tablaMensajes
        } = movement;

        buildCaseAbstract(
          `
           ${atob(clientInfo.rut)}
          `
        );

        let objetosActualizados =  tablaMensajes.map(objeto => {
          // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
          const fechaCreacion = new Date(objeto.fechaCreacion)
          const fechaTermino = objeto.fechaTermino ? new Date(objeto.fechaTermino) : null
          const fechaMasReciente = fechaTermino && fechaTermino > fechaCreacion ? fechaTermino : fechaCreacion
        
          // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
          return {
            estado: objeto.estado,
            fechaActualizacion: fechaMasReciente.toISOString().split('T')[0], // Formateamos como "año-mes-día"
            mensaje: objeto.mensaje,
          }
        })
        if(objetosActualizados.length==0)
          objetosActualizados = tablaMovimientos.map(objeto => {
            // Obtenemos la fecha más reciente entre fechaCreacion y fechaTermino
            const fechaCreacion = new Date(objeto.fechaCreacion)
            // Creamos un nuevo objeto con fechaActualizacion en lugar de fechaCreacion y fechaTermino
            return {
              estado: objeto.estado,
              fechaActualizacion: format(fechaCreacion, 'dd-MM-yyyy'),
              mensaje: objeto.mensaje,
            }
          })
          setCurrentMessages(objetosActualizados);
          setModalVisible(true);
      }else{
        setModalVisible(false);
        notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
      }
  }

  return (
    <View style={(isMobile()?generalStyles.contentContainerResponse:generalStyles.contentContainer)} >
      <HeaderPageTitle title="REVISA EL ESTADO DE TU CAUSA Y ACTUALIZACIONES EN EL PODER JUDICIAL" />

      {/* <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisiblePjud}
        onRequestClose={() => {
          setModalVisiblePjud(!modalVisiblePjud)
        }}
      >
        <View style={styles.centeredView}>
          <View style={ { ...{maxWidth:windowWidth}, ...styles.modalView }}>
            <ScrollView style={{maxHeight: windowHeight+"px"}}>
              {!(currentMessagesPjud || []).length ? (
                <Text style={styles.noDataText}> Sin Información desde el PJUD <br></br></Text>
              ) : (
              <>
                  <TableRowPjud
                    data={{ fecha_doc: "Fecha de Creación", tramite: "Tramite", desc_tramite: "Descripción" , url_doc: "Documento" }}
                    header={true}
                  />
                  {currentMessagesPjud.map((msg, index) => (
                    <TableRowPjud data={msg} key={index} />
                  ))}
              </>
              )}
            </ScrollView>
            <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisiblePjud(false)} />
          </View>
        </View>
      </Modal> */}
      
      
      <>
        <View style={movementsStyles.row}>
          <Text style={[movementsStyles.headerCell, { width: '20vw' }]}>Rol de su causa</Text>
          <Text style={[movementsStyles.headerCell, { width: '20vw' }]}>Tribunal</Text>
          <Text style={[movementsStyles.headerCell, { width: '20vw' }]}>Acciones</Text>
        </View>

        {data ? (
          data.map((item, index) => (
            <View style={movementsStyles.row} key={index}>
              <Text style={[movementsStyles.cell, { width: '20vw' }]}>
                {item.rol}
              </Text>
              <Text style={[movementsStyles.cell, { width: '20vw' }]}>
                {item.tribunal}
              </Text>
              <View style={[movementsStyles.headerCell, { width: '20vw' }]}>

              <ModalBtn
                primaryBtn
                btnText='Ver Movimientos'
                modalTitle='¿En qué estado se encuentra mi causa?'
                onpress={() => movementLookUp(item)}
                keepLoading={!modalAbstract}
                optionalLoaderText='Procesando información, espere un momento...'
                forceClose={!item.activo}
                closeBtnSibilings={
                  <TouchableOpacity
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        backgroundColor: '#74bc1e',
                        paddingVertical: 10,
                        paddingHorizontal: 20,
                        borderRadius: 5,
                      }}
                      onPress={() => window.open('https://agenda.grupodefensa.cl/')}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 10,
                          width: 'fit-content'
                        }}
                      >
                        <Icon
                          name='phone'
                          color='white'
                        />
                        <Text
                          style={{
                            color: 'white',
                            whiteSpace: 'noWrap',
                            fontSize: 'clamp(11px, 1vw, 20px)',
                          }}
                        >
                          Habla con un abogado
                        </Text>
                      </View>
                  </TouchableOpacity>
                
                }
              >
                <View style={styles.container}>
                  <Text 
                    style={styles.paragraph}
                  >
                    {modalAbstract}
                  </Text>

                  <View
                    style={{
                      fontFamily: 'Helvetica', 
                      fontSize: 19, 
                      color: 'blue',
                      marginTop: 40,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 20
                    }}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 448 512"
                      width="50" 
                      height="50"
                      fill="LimeGreen"
                    >
                      <path 
                        d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"
                      />
                    </svg>
                    <a 
                      href='https://agenda.grupodefensa.cl/'
                      target='_blank'
                      style={{textAlign: 'center'}}
                    >
                        Para mayor información te invitamos a agendar una reunión con un abogado
                    </a>
                  </View>
                </View>
                <Text 
                    style={{
                            ...styles.modalTitle, 
                            textAlign: 'left',
                            position: 'sticky',
                            top: '-10px',
                            padding: 20,
                            paddingTop: 30, 
                            zIndex: 2,
                    }}
                  >
                    Historial Completo del caso
                  </Text>
                {currentMessages.length === 0 ? (
                  <Text style={styles.noDataText}> No hay historial disponible <br></br></Text>
                ) : (
                <View
                  style={{
                    marginHorizontal: 20
                  }}
                >       
                    {currentMessages.map( (msgObj, indx) => (
                        <div key={`${msgObj}-${indx}`}>  
                          <Card
                            style={{
                              ...styles.card, 
                              marginLeft: (indx%2 == 0)? '2vw' : '0px' ,
                              marginRight: (indx%2 == 0)? '0px' : '2vw' ,
                            }}
                          >
                            <Card.Content
                              style={{
                                width: '100%',
                                padding: '20px'
                              }}
                            >
                              <Text>
                                {msgObj.mensaje}
                              </Text>
                            </Card.Content>
                            <Card.Actions
                              style={styles.cardDateFooter}
                            >
                              {msgObj.fechaActualizacion}
                            </Card.Actions>
                          </Card>
                        </div>
                  
                    ))}
                </View>
                )}
              </ModalBtn>

                <View style={{height:5}}></View>
                {/* <Button title="Información PJUD" color='green'
                  onPress={() => {
                    if(item.activo){
                      setCurrentMessagesPjud(item.tablaPjud)
                      setModalVisiblePjud(true)
                    }else{
                      notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                    }
                  }} 
                /> */}
                  <ModalBtn
                    btnText='Información PJUD'
                    modalTitle='Historial oficial del Poder Judicial'
                    forceClose={!item.activo}
                    onpress={() => {
                      if(item.activo){
                        setCurrentMessagesPjud(item.tablaPjud);
                      }else{
                        notify('info', 'Para poder hacer seguimiento y revisión de los movimientos de sus casusas, lo invitamos a regularizar sus pagos, comunicándose al email bajas@grupodefensa.cl.')
                      }
                    }}
                    keepLoading={!currentMessagesPjud}
                  >
                   {
                      (
                        Array.isArray(currentMessagesPjud)
                        &&
                        !currentMessagesPjud.length
                      )
                      ? 
                        <>
                          <Text style={styles.noDataText}> Sin Información desde el PJUD <br></br></Text>
                        </>
                      : 
                        <>
                            <TableRowPjud
                              data={{ 
                                fecha_doc: "Fecha de Creación", 
                                tramite: "Tramite", 
                                desc_tramite: "Descripción" , 
                                url_doc: "Documento" 
                              }}
                              header={true}
                            />
                            
                            {(currentMessagesPjud || []).map((msg, index) => (
                              <TableRowPjud data={msg} key={index} />
                            ))}
                        </>
                    }
              </ModalBtn>

                
              </View>
            </View>
            
            
          ))
        ) : !loading ?? <HeaderPageTitle title="Pronto tenedremos informacion de la gestion de tus causas" />}
        {loading && ( <Loading />)}
      </>
      
    </View>
  )
}


const stylesButton = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  line: {
    height: 1,
    backgroundColor: 'gray', // Color de la línea
    marginTop: 8, // Espacio superior para separar la línea del contenido
  }
});


export default withRouter(Movements)
